import React, { useEffect, useState } from "react";
import Layout from "../_components/Layout/Layout";
import MainTitle from "../_components/MainTitle/MainTitle";
import Qr from "../_utils/Qr";
import Database from "../_utils/Database";
import { goto } from "../_utils/Misc";

const QrRewardsFetch = () => {
    const [bodyMessage, setBodyMessage] = useState("Loading");

    useEffect(() => {
        if (!Qr.hasStoredParams() || !Database.getUserToken()) {
            goto("/");
            return null;
        }

        const qrParams = Qr.getStoredParams();

        // Load rewards
        const requestData = {
            "click_id": qrParams.clickId,
            "campaign_id": qrParams.campaignId,
            "code": qrParams.code,
            "variant": qrParams.variant,
            "access_token": qrParams.accessToken
        };

        const requestHeaders = {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${Database.getUserToken()}`,
        };

        // Remove qr params
        Qr.removeStoredParams();

        // Make request
        fetch(`${process.env.GATSBY_FABACUS_API_URL}v1/reward`, {
            method: "POST",
            headers: requestHeaders,
            body: JSON.stringify(requestData),
        })
            .then(async(response) => {
                const responseObject = await response.json();
                const status = response.status;

                if (status === 200 || status === 201) {
                    if (responseObject.top_message) {
                        Database.setRewardsMessage(responseObject.top_message);
                    } else {
                        Database.removeRewardsMessage();
                    }

                    if (responseObject.rewards) {
                        Database.setRewardsList(responseObject.rewards);
                    } else {
                        Database.removeRewardsList();
                    }

                    goto("/qr-rewards/");
                } else {
                    if (responseObject.message_title && responseObject.message_message) {
                        setBodyMessage(`${responseObject.message_title} - ${responseObject.message_message}`);
                    } else {
                        setBodyMessage(`We cannot process your request now. Please try again later.`);
                    }
                }
            })
            .catch(e => {
                console.error("Fetch reward error", e);
                setBodyMessage(`We cannot process your request now. Please try again later.`);
            });
    }, []);

    return (
        <Layout title="Rewards">
            <MainTitle>{bodyMessage}</MainTitle>
        </Layout>
    );
};

export default QrRewardsFetch;
